<template>
  <div class="main graybg">
    <div class="container wrapper-curved">
      <div class="row">
        <div class="col-lg-7 col-md-12 col-md-12 left-area">
          <div class="row mt-2">
            <div class="col">
              <h4 class="left-header">3 million+ freshers <span> registered </span></h4>
              <h2 class="left-subhead">Largest video profile based job portal in India</h2>
              <h6 v-if="!isMobile" class="left-subhead2">Showcase your video profile & <br>apply for matching jobs</h6>        
            </div>
          </div>
          <div class="row" v-if="!isMobile">
            <div class="col">
              <b-card-group deck class="steps-card">
                <b-card title="Step1" :img-src="$S3_url+'/Assets/images/undraw_Resume_re_hkth.svg'" img-alt="Image" img-top>
                  <b-card-text>
                    Register
                  </b-card-text>
                </b-card>
                <b-card title="Step2" :img-src="$S3_url+'/Assets/images/undraw_video_files_fu10.svg'" img-alt="Image" img-top>
                  <b-card-text>
                    Upload your video profile #visume
                  </b-card-text>
                </b-card>
                <b-card title="Step3" :img-src="$S3_url+'/Assets/images/apply-for-jobs.svg'" img-alt="Image" img-top>
                  <b-card-text><h3 style="font-size: 14px;">Start applying for jobs</h3></b-card-text>
                </b-card>
              </b-card-group>
            </div>
          </div>
           <div class="row" v-if="!isMobile">
            <div class="col">
              <div class="container">
                <div >
                  <div class="emp-banner">
                    <b-card overlay :img-src="$S3_url+'/Assets/images/employer-img.svg'" img-alt="Card Image" text-variant="black">
                      <b-card-body>
                        <b-row>
                          <b-col md="12">
                            <b-card-text class="mb-0">
                              <h5 class="text-right ">Are you an employer?</h5>
                            </b-card-text>
                            <b-card-text class="text-secondary text-right ">Looking to hire candidates</b-card-text>
                          </b-col>
                          <b-col md="12" class="employer-btn" >
                              <b-button href="/recruit/login" variant="outline-primary">Register now</b-button>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>					
        </div>
        <div class="col-lg-5 col-md-12 col-md-12 right-area">
          <div class="row">
            <div class="container">
              <div class="col">
                <p class="titleP1" v-if="!isMobile">Start a free account</p>
                <h1>Register & <span>Apply for jobs</span></h1>
                <p class="titleP2">Already a member? <span @click="(route_src) ? $router.push('/jobseeker/login?src='+route_src+($route.query.redirect_from?('&redirect_from='+encodeURIComponent($route.query.redirect_from)):'')):$router.push('/jobseeker/login')">Login</span></p>
              </div>
              <div class="col m-steps hide-ab-991">
                <p><b><span>1</span>Register</b >  ><span>2</span>Upload visume > <span>3</span>Apply for jobs</p>
              </div>
              <div class="col">
                <div class="m-auto">
                  <div class="form-canvas register-form">
                    <form ref="register-form" @submit="postReg">
                      <div label-for="register-email" v-click-outside="hideEmailExtensions">
                        <v-text-field type="text" id="email-input" v-model="register.email"  @keyup="getExtension" @keydown.space.prevent outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        <div class="email-dropdown-list-container" v-show="showExtensions">
                          <ul class="email-dropdown-list">
                            <li tabindex="-1" data-dropdown-item-index= index class="email-dropdown-item" v-for="(domain,index) in selectedDomains" :key="index" @click="setExtension(domain)">
                              <span class="email-dropdown-item-domain">@{{domain}}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="error-feedback" v-if="(typeof validate.response.email != 'undefined' &&!validate.response.email.val)">
                          {{ validate.response.email.msg }}
                        </div>
                        <div class="error-feedback" v-if="email_error&& !validate.response.email.msg && (this.email == register.email)">Email already registered with us <a href="/jobseeker/login">Login</a></div>
                      </div>
                      <div label-for="name-input" class="mt-4">
                        <v-text-field type="text" id="name-input" v-model="register.name" @input="validate.textValidation(register.name, 'name')" outlined><template #label>Full name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        <div class="error-feedback" v-if ="(typeof validate.response.name != 'undefined' && !validate.response.name.val)">
                          {{ validate.response.name.msg }}
                        </div>
                      </div>
                      <div label-for="register-mobile" class="mt-4">
                        <v-text-field type="number" id="mobile-input" v-model="register.mobile" outlined @input="validate.mobileValidation(register.mobile, 'tel')" @keydown.space.prevent><template #label>Mobile<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        <div class="error-feedback" v-if="(typeof validate.response.tel != 'undefined' && !validate.response.tel.val)" >
                          {{ validate.response.tel.msg }}
                        </div>
                        <div class="error-feedback" v-if="(mobile_error&& !validate.response.tel.msg && (this.mobile == register.mobile))">
                          {{mobile_msg}}
                        </div>
                      </div>
                      <div label-for="register-password" class="mt-4">
                        <v-text-field id="password-input" :append-icon="show1 ? $icons.eye : $icons.eyeOff" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"  v-model="register.password" @input="validate.passwordValidation(register.password, 'password')" outlined><template #label>Password<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        <div class="error-feedback" v-if="(typeof validate.response.password != 'undefined' && !validate.response.password.val)">
                          {{ validate.response.password.msg }}
                        </div>
                      <div class="invalid-feedback" v-if="pass_error">{{pass_msg}}</div>
                      </div>
                      <div label-for="register-jobrole" class="mt-4">
                        <v-autocomplete id="role-input" v-model="register.jobrole" :items="jobrole" item-value="value" item-text="text" @input="validate.jobroleValidation(register.jobrole, 'jobrole')" :class="{'is-invalid':typeof validate.response.jobrole != 'undefined' &&!validate.response.jobrole.val,}" clearable chips outlined multiple deletable-chips><template #label>Jobrole<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                        <div class="error-feedback" v-if="(typeof validate.response.jobrole != 'undefined' && !validate.response.jobrole.val)">
                          {{ validate.response.jobrole.msg }}
                        </div>
                      </div>
                      <div  label-for="register-experience" class="mt-4">
                        <v-autocomplete  id="exp-input" :items="exp.options" item-value="value" item-text="text" :searchable="true" v-model="experience" @change="showmore" :class="{'is-invalid':(can_register != 0 && !experience)}" clearable outlined><template #label>Experience<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                        <div class="error-feedback" v-if="(can_register != 0 && !experience)">
                          {{ validate.response.experience.msg }}
                        </div>
                      </div>

                      <div label-for="register-qualification" v-show="moreinfo && experience" class="mt-4">
                        <b-button class="btn-help" style="right:-5px;" v-b-tooltip.click="'If you are a final year student, enter the details of the last semester for which results have been published.'">i</b-button>
                        <v-autocomplete id="qual-input" v-model="register.highest_qualification" :searchable="true" :items="courses" item-text="text" item-value="id" @change="getSpecification" @input="validate.checkIfEmpty(register.highest_qualification_percentage,'percentage','Select Highest Qualification Percentage')" :class="{'is-invalid':(can_register != 0 && !register.highest_qualification && moreinfo)}" outlined>
                          <template #label>Highest qualification<span class="red--text"><strong>* </strong></span></template>
                          <template slot="item" slot-scope="data">
                            <template v-if = typeof(data.item)>
                              <v-list-item-content>{{data.item.text}}</v-list-item-content>
                            </template>
                            <!-- <template v-else>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.text"/>
                                <v-list-item-sub-title v-html="data.item.group"/>
                              </v-list-item-content>
                            </template> -->
                          </template>
                        </v-autocomplete>
                        <div class="error-feedback" v-if="(can_register != 0 && !register.highest_qualification)">
                          {{ validate.response.qualification.msg }}
                        </div>
                      </div>

                      <div label-for="register-specialisation" v-show="speci" class="mt-4">
                        <v-autocomplete id="spec-input" v-model="register.specialisation" v-show="speci" :items="spec" item-value="value" item-text="text" @change="regedu" outlined><template #label>Specialisation<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                        <div class="error-feedback" v-if="(can_register != 0 && !register.specialisation)">
                          {{ validate.response.specialisation.msg }}
                        </div>
                      </div>

                      <div v-show="more">
                        <div label-for="register-percentage" class="mt-4">
                          <v-text-field id="perc-input" v-model="register.highest_qualification_percentage" type="number" @input="validate.percentageValidation(register.highest_qualification_percentage,'percentage')" outlined><template #label>Highest qualification %<span class="red--text"><strong>* </strong></span></template></v-text-field>
                          <div class="error-feedback" v-if="((typeof validate.response.percentage != 'undefined' && !validate.response.percentage.val) && more)">
                            {{ validate.response.percentage.msg }}
                          </div>
                        </div>

                        <div label-for="register-passout" class="mt-4">
                          <v-autocomplete id="pass-input" v-model="register.passout_year" :items="years" item-value="value" item-text="text" outlined><template #label>Year of passout<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                          <div class="error-feedback" v-if="(can_register !=0 && !register.passout_year && more)">
                            {{ validate.response.passout.msg }}
                          </div>
                        </div>
                      </div>

                      <div label-for="register-state" class="mt-4">  
                        <v-autocomplete id="state-input" v-model="register.city" :items="locations" item-text="text" item-value="id" clearable outlined> 
                          <template #label>Current city<span class="red--text"><strong>* </strong></span></template>
                          <template slot="item" slot-scope="data">
                            <template v-if = typeof(data.item)>
                              <v-list-item-content>{{data.item.text}}</v-list-item-content>
                            </template>
                            <!-- <template v-else>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.text"/>
                                <v-list-item-sub-title v-html="data.item.group"/>
                              </v-list-item-content>
                            </template> -->
                          </template>
                        </v-autocomplete>
                        <div class="error-feedback" v-if="(can_register != 0 && !register.city)">{{ validate.response.city.msg }}</div>
                      </div>

                      <div label-for="register-resume" class="text-left mt-4" >
                        <!-- <v-file-input v-model="resume" label="Post resume" append-icon="mdi-file" prepend-icon="" show-size outlined></v-file-input> -->
                        <b-form-file v-model="resume"  placeholder="Post resume" drop-placeholder="Drop file here..." accept=".doc,.docx,.rtf,.pdf">
                          <span class="mdi mdi-file mr-2"></span> Post resume
                        </b-form-file>
                      </div>
                      <div v-if="institute_reg">
                        <div label-for="institute-name" class="mt-4">
                          <v-text-field type="text" id="institute_name" disabled v-model="register.institute.institute_name"  outlined><template #label>Institute name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        </div>
                        <div id="institute_course" label-for="Choose course" class="mt-5">
                          <v-autocomplete no-filter ref="autocomplete" v-model="register.institute.course_name" label="Select course" :items="institute_courses"  @input="handleInstiInput" item-text="text" item-value="value" :searchable="false" clearable outlined deletable-chips> 
                            <template slot="item" slot-scope="data">
                              <template>
                                <v-list-item-content>{{data.item.text}}</v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>   
                          <div class="error-feedback" v-if="(typeof validate.response.course_name != 'undefined' && !validate.response.course_name.val)">{{ validate.response.course_name.msg }}</div>
                        </div>
                        <div id="other_course_name" label-for="other_course_name" class="mt-5 mb-5">
                          <v-text-field  type="text" v-if="register.institute.show_other_course" v-model="register.institute.other_course_name" label="Enter course name"  outlined  @input="validate.checkIfEmpty(register.institute.other_course_name, 'other_course_name', 'Please enter course name');"></v-text-field>
                          <div class="error-feedback" v-if="(typeof validate.response.other_course_name != 'undefined' && !validate.response.other_course_name.val && register.institute.course_name == 'other')">
                            {{ validate.response.other_course_name.msg }}
                          </div>
                        </div>
                        <div id="training_location" label-for="Choose location" class="mt-5">
                          <v-autocomplete ref="autocomplete" v-model="register.institute.training_location" label="Choose location" :items="locations" item-text="text" item-value="id" :searchable="false" clearable outlined deletable-chips> 
                            <template slot="item" slot-scope="data">
                              <template>
                                <v-list-item-content>{{data.item.text}}</v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>   
                          <div class="error-feedback" v-if="(typeof validate.response.training_location != 'undefined' && !validate.response.training_location.val)">
                          {{ validate.response.training_location.msg }}
                        </div>
                          <!-- <div class="error-feedback" v-if="(typeof validate.response.institute_name != 'undefined' && !validate.response.institute_name.val)">{{ validate.response.institute_name.msg }}</div> -->
                        </div>
                        <div id="categories" v-if="register.institute.show_other_course" label-for="Choose Category" class="mt-5">
                          <v-autocomplete ref="autocomplete" v-model="register.institute.course_category_id" label="Choose course category" :items="categories" item-text="text" item-value="value" :searchable="false" clearable outlined deletable-chips> 
                            <template slot="item" slot-scope="data">
                              <template>
                                <v-list-item-content>{{data.item.text}}</v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>   
                          <div class="error-feedback" v-if="(typeof validate.response.course_category != 'undefined' && !validate.response.course_category.val && register.institute.course_name == 'other')">{{ validate.response.course_category.msg }}</div>
                        </div>
                        <div id="course_start_date" label-for="course_start_date" class="mt-5 mb-5">
                          <v-text-field  type="date" v-model="register.institute.course_start_date" label="Course start date"  outlined></v-text-field>
                          <div class="error-feedback" v-if="(typeof validate.response.course_start_date != 'undefined' && !validate.response.course_start_date.val)">
                            {{ validate.response.course_start_date.msg }}
                          </div>
                        </div>
                        <div id="course_end_date" label-for="course_end_date" class="mt-5 mb-5">
                          <v-text-field  type="date" v-model="register.institute.course_end_date" label="Course end date"  outlined></v-text-field>
                          <div class="error-feedback" v-if="(typeof validate.response.course_end_date != 'undefined' && !validate.response.course_end_date.val)">
                            {{ validate.response.course_end_date.msg }}
                          </div>
                        </div>
                      </div>
                      <div v-if="college_reg">
                        <div label-for="college-name" class="mt-4">
                          <v-text-field type="text" id="college_name" disabled v-model="register.college.college_name"  outlined><template #label>College name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        </div>
                      </div>
                      <div label-for="check-sign" class="mt-4">
                        <b-form-checkbox id="checkbox-1" name="checkbox-1" value="true" v-model="subscribe" unchecked-value='false' class="text-secondary">
                          <span>I hereby subscribe to newsletters and job updates from Hireclap</span>
                        </b-form-checkbox>
                      </div>
                      <div class="form-group ">
                        <b-button type="submit" class="mr-3 mt-3 btn-signup" style="color:#fff !important" variant="primary" v-if="!isSigning">Register</b-button>
                        <b-button type="submit" class="mr-3 mt-3 btn-signup"  style="cursor: no-drop;color:#fff !important;font-size:10px;" variant="primary" v-if="isSigning" disabled>Registering...</b-button>
                        <div v-if="error" style="margin-top:10px!important;" class="error-feedback">{{error_message}}</div>
                        <div class="row m-2">
                          <p class="text-secondary mb-0"><small class="mb-0"><i>By creating an account, you agree to Hireclap<a href="/terms" target="_blank">Terms & conditions</a> &<a href="/privacy" target="_blank"> Privacy policy.</a></i></small></p>
                        </div>
                      </div>
                    </form>
                  </div>
								</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="reg-success" ref="reg-success" title="Registration Successfull" hide-footer no-close-on-backdrop>
      <div class="container">
        <div class="col-lg-12 bg-white" style="padding: 0.5rem 2.5rem">
          <p>Thanks for registering with Hireclap.</p>
           <a class="text-decoration-none" href="/jobseeker/login"><strong> Sign in</strong></a>
           <p>Add video resume(Visume) in your profile & start applying for job</p>
        </div>
      </div>
    </b-modal>
    <!-- <Modals :open="modals.open"/> -->
    <VisumeModal :visume="null" :email_status="email_status" :open="visumeModal.open"/>
  </div>
</template>

<script>
const VisumeModal = () => import(/* webpackPrefetch: true */ "@/components/VisumeModal");

import Actions from '../libraries/apiActions.js'
import AwsUpload from "../../utils/s3upload"
import validation from '../libraries/validFormat'; 
import { mapState, mapGetters } from 'vuex';
import store from '../store';
import { COURSES } from '../store/modules/courses/constants';
import { STATES } from '../store/modules/states/constants';
import { JOB_ROLE } from '../store/modules/jobrole/constants';
// import {eventBus} from '../main';
import '../assets/hire.css'

var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Register",
        "item": window.location.href
      }]
    }
export default {
  name: "SeekerReg",
  components: {
    VisumeModal
  },
  metaInfo(){
    return {
      link: [
        { rel: 'canonical', href: process.env.VUE_APP_URL+'jobseeker/register'}
      ],
      script: [
      {type: 'application/ld+json', json: breadcrumbJson},
    ]
    }
  },
  beforeCreate() {
      this.$store = store;
  },
  beforeMount(){
    this.getYears();
    this.route_src = this.$route.query.src;
    this.register.redirect_from = this.$route.query.redirect_from?this.$route.query.redirect_from:null;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
    if(this.$route.query.i){
      this.getCategories();
      this.default_institute_id = Buffer.from(decodeURIComponent(this.$route.query.i), 'base64').toString();
      this.action.getInstituteDetails(this.default_institute_id).then((res) => {
        if(res.status && res.institute){
          this.default_institute = res.institute;
          this.register.institute = {
            default: true,
            st_institute_id: this.default_institute.st_institute_id,
            institute_name: this.default_institute.institute_name,
            institute_id: this.default_institute.institute_id,
            course_name: '',
            course_start_date: null,
            course_end_date: null,
            training_location: null,
            show_other_course: false,
            course_category_id: null,
            course_id: null
          }
          this.getInstituteCourse(this.default_institute.st_institute_id);
          this.institute_reg = true;
          this.route_src = 'institute-'+this.default_institute.institute_name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        }
      })
    }else if(this.$route.query.c){
      this.default_college_id = Buffer.from(decodeURIComponent(this.$route.query.c), 'base64').toString();
      console.log(this.default_college_id);
      this.action.getCollegeDetails(this.default_college_id).then((res) => {
        if(res.status && res.college){
          this.default_college = res.college;
          this.register.college = {
            default: true,
            st_college_id: this.default_college.st_college_id,
            college_id: this.default_college.college_id,
            college_name: this.default_college.college_name,
          }
          this.college_reg = true;
          this.route_src = 'college-'+this.default_college.college_name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        }
      })
    }
  },
  created() {
    const apiFn = this.action;
    this.cachedDispatch(COURSES.GET_COURSE, { param: apiFn });
    this.cachedDispatch(STATES.GET_STATES, { param: apiFn });
    this.cachedDispatch(JOB_ROLE.GET_JOB_ROLE, { param: apiFn });
  },
  computed: {
    ...mapGetters(['cachedDispatch']),
    ...mapState({
        courses: state => state.courses.courses.courses,
        locations: state => state.states.locations.locations,
        jobrole: state => state.jobrole.jobrole.jobrole
    })
  },
  data(){
    return{
      route_src: '',
      validate: new validation(),
      action: new Actions(),
      max:4,
      // modals:{ 
      //   open:'exit',
      // },
      visumeModal:{
        open:'exit',
      },
      // jobrole:[],
      spec:[],
      states:[],
      // courses:[],
      sublocations:[],
      subCity:false,
      more:false,
      moreinfo:false,
      speci:false,
      metro_cities:[],
      exp: {
        options: [
          { value: 1, text: "0 Month(Fresher)" },
          { value: 6, text: "06 Months" },
          { value: 12, text: "1 Year" },
          { value: 24, text: "2 year" },
          { value: 36, text: "3 year" },
          { value: 48, text: "4 year" },
          { value: 60, text: "5 year" },
          { value: 72, text: "6 year" },
          { value: 84, text: "6+ year" },
        ],
      },
      register: {
        name: '',
        email: null,
        password: '',
        mobile: '',
        jobrole: [],
        experience: "",
        highest_qualification: "",
        specialisation: "",
        highest_qualification_percentage: null,
        passout_year: null,
        city: "",
        sub_location: null,
        resume: null,
        src: null,
        redirect_from: null,
      },
      experience:"",
      can_register:0,
      mobile_error:false,
      pass_error:false,
      email_error:false,
      mobile : null,
      email : null,
      mobile_msg : '',
      pass_msg : '',
      email_msg : '',
      subscribe:true,
      resume:null,
      domains: [
        "gmail.com",
        "yahoo.com",
        "yahoo.co.in",
        "yahoo.in",
        "rediffmail.com",
        "outlook.com",
        "hotmail.com",
        "ymail.com",
        "live.com",
        "mail.com",
        "rocketmail.com",
        "aol.com",
        "in.com",
        "sify.com"
      ],
      selectedDomains:[],
      showExtensions:false,
      years:[],
      seeker:null,
      isSigning:false,
      isMobile:false,
      show1:false,
      email_status:null,
      error:false,
      error_message:null,
      institute_reg: false,
      default_institute_id: null,
      default_institute: null,
      institute_courses: [],
      institutes: [],
      categories:[],
      college_reg: false,
      default_college_id: null,
      default_college: null
    }
  },
  methods:{
    openModal: function (type = "") {
      this.open = type == "" ? this.open : type;
      if(this.open == "confirmation") {
        this.$refs["reg-success"].show();
      }
      this.visumeModal.open = 'exit';
      this.visumeModal.open = type;
    },
     handleInstiInput: function(value){
      if(value == 'other'){
        this.register.institute.show_other_course = true;
      } else this.register.institute.show_other_course = false;
    },
    // setInstikeyword() {
    //   var input_str = this.$refs.autocomplete.$el.querySelector('input').value;
    //   if (input_str.length >= 3) {
    //     this.getInstituteCourse(input_str)
    //   }
    // },
    getCategories: async function() {
      await this.action.getCourseCategories().then(async (data) => {
        data.categories.forEach(category_type => {
          this.categories.push({ header: category_type.course_category_name });
          this.categories.push({divider:true})

          category_type.categories.forEach(cat => {
            this.categories.push({
              value: cat.category_id,
              text: cat.category_name
            });
          });
        });
      });
    },
    getInstituteCourse: function(id){
        this.institute_courses = [];
        this.action.getInstituteCourses(id).then((res) => {
          this.institute_courses = [{
              text: 'Other',
              value: 'other'
          }];
        if(res.courses.length > 0){
          res.courses.forEach((e) => {
            this.institute_courses.push({
              value: e.course_name,
              text: e.course_name,
              id: e.cid
            });
          });
        } 
      });
    },
    resetForm:function(){
        this.register.name = null,
        this.register.email =  null,
        this.register.password= null,
        this.register.mobile= null,
        this.register.jobrole= [],
        this.register.experience= "",
        this.register.highest_qualification= "",
        this.register.specialisation= "",
        this.register.highest_qualification_percentage= null,
        this.register.passout_year= null,
        this.register.city= "",
        this.register.sub_location= null,
        this.register.resume= null,
        this.experience = "",
        this.isSigning = false
    },
    getExtension(e){
      if(e.key == "@"){
        this.showExtensions = true;
        this.selectedDomains = this.domains
      }else{
        if(this.register.email.includes("@")){
          this.showExtensions = true;
          this.selectedDomains = [];
          var split_email = this.register.email.split("@");
          this.domains.forEach(domain => {
            if(domain.includes(split_email[1])){
              this.selectedDomains.push(domain);
              if(domain == split_email[1]){
                this.showExtensions = false;
              }
            }
          });
        }else{
          this.showExtensions = false
        }
      }
    },
    setExtension(ext){
      var split_email = this.register.email.split("@");
      this.register.email = split_email[0]+"@"+ext;
      this.showExtensions = false;
    },
    hideEmailExtensions(){
      this.showExtensions = false;
      this.selectedDomains = [];
    },
    checkMaximum(){
      if(parseInt(this.register.highest_qualification_percentage) >= 100){
        return this.register.highest_qualification_percentage.substring(0,2);
      }else{
        return this.register.highest_qualification_percentage;
      }
    },
    // getJobrole: function () {
    //   this.action.getJobrole().then((data) => {
    //     data.jobroles.forEach((element) => {
    //       this.jobrole.push({
    //         value: element.role_id,
    //         text: element.role,
    //       });
    //     });
    //   });
    // },
    limiter(e) {
      if (e.length > 3) {
        this.jobrole_error = true;
        this.error_msg = "Select maximum 3 preferred job role";
        e.pop();
      }
    },
    showmore: function () {
      this.moreinfo = false;
      this.speci = false;
      this.more = false;
      this.register.highest_qualification = "";
      this.register.specialisation = "";
      this.register.highest_qualification_percentage = "";
      this.validation = true;
      if (this.experience < 36) {
        this.moreinfo = true;
      }
    },
    getYears(){
      var year = new Date().getFullYear();
      for(var i = year-24; i <year; i++){
        this.years.push({value:i, text:i});
      }
      for(i = year; i<=year+6; i++){
        this.years.push({value:i, text:i});
      }
    },
    getSpecification: function () {
      this.spec = [];
      this.register.specialisation = "";
      this.validation = true;
      if (this.register.highest_qualification != null) {
        this.speci = true;
      }
      this.action.getSpec(this.register.highest_qualification).then((data) => {
          data.specifications.forEach((element) => {
            this.spec.push({
              value: element.bid,
              text: element.branch_name,
            });
          });
        });
    },
    regedu: function () {
      this.more = false;
      this.validation = true;
      if (this.register.specialisation != "") {
        this.more = true;
      }
    },
    getSubCities: function () {
      this.sublocations = [];
      this.register.sub_location = "";
      this.subCity = false;
      if (this.register.city != "") {
        this.action.getSubcity(this.register.city).then((data) => {
          if(data.sublocations){
            data.sublocations.forEach((element) => {
                this.subCity = true;
                this.sublocations.push({
                  value: element.sublocation_id,
                  text: element.sublocation_name,
                });
            });
          }
        });
      }
    },
    postReg: async function(e){
      e.preventDefault();
      this.can_register = 0;
      this.email_error = false;
      this.pass_error = false;
      this.mobile_error = false;
      this.mobile = null;
      this.email = null;
      this.mobile_msg = '',
      this.pass_msg = '';
      this.email_msg = '';
      this.error = false;
      this.error_message = '';
      if (this.validate.emailValidation(this.register.email, "email")?.val === false) {
        this.can_register++;
      }
      if (this.validate.mobileValidation(this.register.mobile, "tel")?.val === false) {
        this.can_register++;
      }
      if (this.validate.textValidation(this.register.name, "name")?.val === false ) {
        this.can_register++;
      }
      if (this.validate.passwordValidation(this.register.password, "password")?.val === false) {
        this.can_register++;
      }
      if (this.validate.jobroleValidation(this.register.jobrole, "jobrole")?.val === false) {
        this.can_register++;
      }
      if(this.validate.checkIfEmpty(this.experience, "experience", 'Select experience')?.val === false){
        this.can_register++;
      }
      if(this.validate.checkIfEmpty(this.register.highest_qualification, "qualification", 'Select Highest Qualification')?.val === false && this.moreinfo){
        this.can_register++;
      }
      if(this.validate.checkIfEmpty(this.register.specialisation, "specialisation", 'Select Specialisation')?.val === false && this.speci){
        this.can_register++;
      }
      if(this.validate.percentageValidation(this.register.highest_qualification_percentage, "percentage")?.val === false && this.more){
        this.can_register++;
      }
      if(this.validate.checkIfEmpty(this.register.passout_year, "passout", 'Select Year of passout')?.val === false && this.more){
        this.can_register++;
      }
      if(this.validate.checkIfEmpty(this.register.city, "city", 'Select City')?.val === false){
        this.can_register++;
      }
      if(this.institute_reg && this.validate.checkIfEmpty(this.register.institute.training_location, "training_location", 'Choose training location')?.val === false){
        this.can_register++;
      }
      if(this.institute_reg && this.validate.checkIfEmpty(this.register.institute.course_name, "course_name", 'Choose training course')?.val === false){
        this.can_register++;
      }
      if(this.institute_reg && this.register.institute.course_name == 'other' && this.validate.checkIfEmpty(this.register.institute.other_course_name, "other_course_name", 'Enter course name')?.val === false){
        this.can_register++;
      }
      if(this.institute_reg && this.register.institute.course_name == 'other' && this.validate.checkIfEmpty(this.register.institute.course_category_id, "course_category", 'Choose course  category')?.val === false){
        this.can_register++;
      }
      if(this.institute_reg && this.validate.checkIfEmpty(this.register.institute.course_start_date, "course_start_date", 'Choose course start date')?.val === false){
        this.can_register++;
      }
       if(this.institute_reg && this.validate.checkIfEmpty(this.register.institute.course_end_date, "course_end_date", 'Choose course end date')?.val === false){
        this.can_register++;
      }
      // if(this.validate.checkIfEmpty(this.resume, "resume", 'Upload Resume')?.val === false){
      //   this.can_register++;
      // }
      if(this.institute_reg){
        console.log(this.register.institute.course_name);
        if(this.register.institute.course_name == 'other'){
          this.register.institute.course_name = this.register.institute.other_course_name;
          this.register.institute.course_id = null;
        } else {
          var selected_course = this.institute_courses.find(option => option.value === this.register.institute.course_name);
          if(selected_course)
          this.register.institute.course_id = selected_course.id;
        }
      }
      if(this.can_register == 0){
        this.isSigning = true;
        if(this.experience == 1){
          this.register.experience = 0;
        }else{
          this.register.experience = this.experience;
        }
        
        this.register.src = (this.route_src)? this.route_src: 'home-register';
        if(this.resume != null){
          const file = this.resume;
          const awsUploader = new AwsUpload();
            try {
              var extension = this.resume.name.split('.')[this.resume.name.split('.').length-1]
              var d = new Date();
              var month = d.getMonth() + 1;
              var day = d.getDate();
              var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
              var timestamp = Math.floor(Date.now() / 1000);
              var filename = 'resume/'+ current_date+"/"+this.register.name + timestamp + "."+extension;
              const response = await awsUploader.uploadToS3(file, filename);
              this.register.resume = response.url;
              this.seekerRegistration();
            } catch (error) {
              this.error = true;
              this.error_message = "Resume uploading error,please try again."
              this.isSigning = false;
              console.error('Failed to upload file to S3:', error);
            }
        }else{
            this.seekerRegistration();
        }
      }else{
        this.error = true;
        this.isSigning = false;

        if (this.validate.emailValidation(this.register.email, "email")?.val === false) {
          document.getElementById('email-input').scrollIntoView(true);
          var scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if (this.validate.textValidation(this.register.name, "name")?.val === false ) {
          document.getElementById('name-input').scrollIntoView(true);
          // now account for fixed header
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.mobileValidation(this.register.mobile, "tel")?.val === false) {
          document.getElementById('mobile-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if (this.validate.passwordValidation(this.register.password, "password")?.val === false) {
          document.getElementById('password-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if (this.validate.jobroleValidation(this.register.jobrole, "jobrole")?.val === false) {
          document.getElementById('role-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.checkIfEmpty(this.experience, "experience", 'Select experience')?.val === false){
          document.getElementById('exp-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.checkIfEmpty(this.register.highest_qualification, "qualification", 'Select Highest Qualification')?.val === false && this.moreinfo){
          document.getElementById('qual-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.checkIfEmpty(this.register.specialisation, "specialisation", 'Select Specialisation')?.val === false && this.speci){
          document.getElementById('spec-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.percentageValidation(this.register.highest_qualification_percentage, "percentage")?.val === false && this.more){
          document.getElementById('perc-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.checkIfEmpty(this.register.passout_year, "passout", 'Select Year of passout')?.val === false && this.more){
          document.getElementById('pass-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }else if(this.validate.checkIfEmpty(this.register.city, "city", 'Select City')?.val === false){
          document.getElementById('state-input').scrollIntoView(true);
          scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 100);
          }
        }

        if(window.navigator.onLine){
          this.error_message = "Error in some fields, please scroll up, correct & resubmit";
        }else{
          this.error_message = "Network error! Please check your internet connection.";
        }
      }
    },
    seekerRegistration(){
      this.error = false;
      this.error_message = '';
      this.action.seekerRegister(this.register).then((res) => {
        if (res.message == "Jobseeker registered successfully") {
          this.isSigning = false;
          this.seeker = res.jobseeker;
          this.email_status = res.jobseeker.status;
          this.$cookies.set('seeker', this.seeker.id);
          this.resetForm();
          this.openModal("visume");
          // this.$router.push('/jobseeker/login');
          // this.openModal("confirmation");
        }else{
          this.isSigning = false;
          this.error_message='Error in some fields, please scroll up, correct & resubmit'
          if(res.email?.length){
            if(this.institute_reg){
              this.$router.replace({name:'JobseekerSign',params:{institute: this.register.institute}});
              console.log(res);
            }else if(this.college_reg){
              let req = {
                st_college_id: this.default_college.st_college_id,
                college_id: this.default_college.college_id,
                course_id: this.register.highest_qualification,
                branch_id: this.register.specialisation,
                aggregate_marks: this.register.highest_qualification_percentage,
                passout_year: this.register.passout_year,
              };
              this.$router.replace({name:'JobseekerSign',params:{college: req}});
            } else {
              this.email_error = true;
              this.email = this.register.email;
              this.email_msg = 'Email already registered with us Login';
              document.getElementById('email-input').scrollIntoView(true);
              var scrolledY = window.scrollY;
              if(scrolledY){
                window.scroll(0, scrolledY - 100);
              }
            }
          } 
          if(res.password?.length){
            this.pass_error = true;
            this.pass_msg = res.password[0];
            document.getElementById('pass-input').scrollIntoView(true);
            scrolledY = window.scrollY;
            if(scrolledY){
              window.scroll(0, scrolledY - 100);
            }
          }
          if(res.mobile?.length){
            this.mobile_error = true;
            this.mobile = this.register.mobile;
            this.mobile_msg = res.mobile[0];
            document.getElementById('mobile-input').scrollIntoView(true);
            scrolledY = window.scrollY;
            if(scrolledY){
              window.scroll(0, scrolledY - 100);
            }
          }
        }
      }).catch(() => {
        this.isSigning = false;
        this.error=true;
        if(window.navigator.onLine){
          this.error_message = "System error! Please try again.";
        }else{
          this.error_message = "Network error! Please check your internet connection.";
        }
      });
    },
    checkUploaded(value){
      console.log(value);
    },
    sendVerification: function() {
      this.notverified_error = false;
      this.action.sendVerification(this.login).then((res) => {
          if(res.status) {
           this.success_msg = "Verification email has been send"
          } else {
            this.error_msg = res.error
          }
          }).catch((err) => {
          if(err){
            console.log(err);
            if(window.navigator.onLine){
              this.error_msg = "System error! Please try again.";
            }else{
              this.error_msg = "Network error! Please check your internet connection.";
            }
          }
        });
    },
  },
}
</script>